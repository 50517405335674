<template>
  <CCard>
    <CCardHeader class="card-header">{{ $t("USER_MANAGEMENT.PROFILE.CARD_HEADER") }}</CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="3" xl="3">
          <avatar
            :username="getMerchantUserName()"
            :size="80"
            font-weight="34px"
            class="margin-left"
            color="white"
            background-color="#725CF6"
          ></avatar>
        </CCol>
        <CCol md="9" xl="9">
          <div class="margin-content">
            <div class="text-muted">{{ $t("USER_MANAGEMENT.PROFILE.USER_EMAIL") }}</div>
            <strong>{{ getUserEmail() }}</strong>
            <br />
            <br />
            <img
              src="../../assets/img/change_password_button.svg"
              class="pointer-on-hover"
              @click="goTo('/user/change-password')"
            />
            <br />
            <br />
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import Avatar from "vue-avatar";
export default {
  name: "Profile",
  components: { Avatar }
};
</script>
<style scoped>
@media screen and (min-width: 780px) {
  .margin-content {
    margin-left: -15%;
  }
}
.margin-left {
  margin-left: 10%;
}
.card-header {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
}
</style>